<template>
    <transition name="fade" mode="out-in">
        <div
            class="input-container"
            :style="containerStyle"
            ref="inputContainer"
            :data-error-msg="invalidMsg"
        >
            <div v-if="title" class="form-label">
                <span v-if="required" class="required">*</span><span></span>
                <span v-text="title" />
                <div
                    v-if="tips"
                    class="info-icon"
                    :tooltip="tips"
                    tooltip-touch
                />
            </div>
            <!-- 客製化日期遮罩 input -->
            <input
                v-if="type === 'date' && allowInputDate"
                type="date"
                placeholder="YYYY/MM/DD"
                data-customer-mask
                :class="[
                    textboxStyleClass,
                    { focus: isFocus, disabled: disabled }
                ]"
                :name="name"
                :max="dateMax"
                :min="dateMin"
                :required="required"
                :disabled="disabled"
                :value="modelValue"
                @mouseenter="false /*hoverDateHandler('input', 'enter')*/"
                @mouseleave="false /*hoverDateHandler('input', 'leave')*/"
                @click.prevent="clickDateHandler"
                @blur="blurDateHandler"
                @input="inputDateHandler"
            />
            <FlatPickr
                v-if="type === 'date'"
                v-show="isFirefox()"
                ref="flatPickr"
                :placeholder="switchColumnDefaultStyle"
                :name="name"
                :config="flatpickrOptions"
                :required="required"
                :disabled="disabled"
                :class="{ disabled: disabled }"
                v-model="dateValue"
                @change="changeDateHandler"
            />
            <input
                v-if="type === 'number'"
                type="text"
                class="number"
                autocomplete="off"
                :class="textboxStyleClass"
                :name="name"
                :placeholder="placeholder"
                :max="max"
                :min="min"
                :maxlength="maxlength"
                :required="required"
                :disabled="disabled"
                :value="modelValue"
                @input="inputHandler"
                @click="setInputGa()"
            />
            <input
                v-if="type === 'text'"
                type="text"
                autocomplete="off"
                :class="textboxStyleClass"
                :name="name"
                :placeholder="placeholder"
                :required="required"
                :disabled="disabled"
                :value="modelValue"
                :maxlength="maxlength"
                @input="inputHandler"
                @click="setInputGa()"
            />
            <input
                v-if="type === 'email'"
                type="email"
                :class="textboxStyleClass"
                :name="name"
                :placeholder="placeholder"
                :required="required"
                :disabled="disabled"
                :value="modelValue"
                @input="inputHandler"
                @click="setInputGa()"
            />
            <input
                v-if="type === 'password'"
                type="password"
                :class="textboxStyleClass"
                :name="name"
                :placeholder="placeholder"
                :required="required"
                :disabled="disabled"
                :value="modelValue"
                :maxlength="maxlength"
                @input="inputHandler"
                @click="setInputGa()"
            />
            <div v-if="isError" class="error-message">{{ errorMessage }}</div>
        </div>
    </transition>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr'
import { textBoxGaSetting } from '@/assets/javascripts/commonGaSetting'

export default {
    name: 'Textbox',
    components: {
        FlatPickr
    },
    emits: ['update:modelValue'],
    props: {
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        name: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isError: {
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String
        },
        onlyMonth: {
            type: Boolean,
            default: false
        },
        onlyDate: {
            type: Boolean,
            default: false
        },
        isMonthLastDay: {
            type: Boolean,
            default: false
        },
        width: {},
        max: {},
        min: {},
        tips: {
            type: String,
            default: ''
        },
        maxlength: {
            type: [Number, String]
        },
        inputErrorMsg: {
            type: String,
            default: ''
        }
    },
    methods: {
        clickDateHandler: function () {
            this.setInputGa()
            this.showPlaceholder = false
            this.$refs.flatPickr.fp.toggle()
        },
        blurDateHandler: function () {
            if (!this.$refs.flatPickr.fp.isOpen) {
                this.showPlaceholder = !this.modelValue
            }
        },
        inputHandler: function () {
            if (this.type === 'number') this.inputDollarHandler(event)
            this.$emit('update:modelValue', event.target.value)
        },
        changeDateHandler: function () {
            let date = event.target.value
            if (date) {
                date = new Date(date)
                if (this.onlyMonth && this.isMonthLastDay) {
                    // 變成當月最後一天
                    date = new Date(date.getFullYear(), date.getMonth() + 1, 1)
                }
                if (this.onlyMonth && this.dateMax) {
                    const maxDate = new Date(this.dateMax)
                    date = date > maxDate ? maxDate : date
                }
                date = this.$formatDate(date)
            }
            this.$emit('update:modelValue', date)
        },
        inputDateHandler: function () {
            this.dateValue = event.target.value
            this.$emit('update:modelValue', event.target.value)
        },
        inputDollarHandler: function (event) {
            let element = event.target
            let temp, paste
            switch (event.inputType) {
                case 'insertText':
                    this.setInputComma(event, element)
                    break
                case 'insertCompositionText':
                    this.setInputComma(event, element)
                    break
                case 'insertFromPaste':
                    temp = ''
                    paste = element.value
                    for (let i = 0; i < paste.length; i++) {
                        let str = paste.substr(i, 1)
                        if (this.validateNumber(str)) {
                            temp += str
                        }
                    }
                    element.value = this.$numberWithComma(temp)
                    break
                default:
                    element.value = this.$numberWithComma(element.value)
            }
        },
        hoverDateHandler: function (element, eventName) {
            let fp = this.$refs.flatPickr.fp
            this.isHovering[element] = eventName === 'enter'
            setTimeout(() => {
                this.isHovering['input'] || this.isHovering['calendar']
                    ? fp.open()
                    : fp.close()
            }, 100)
        },
        isFirefox: function () {
            return navigator.userAgent.indexOf('Firefox') !== -1
        },
        setInputComma: function (event, element) {
            const pNumber = event.data
            if (!this.validateNumber(pNumber)) {
                const previousNumber = element.value.slice(0, -1)
                const number = this.$numberWithComma(previousNumber)
                element.value = number
            } else {
                const number = this.$numberWithComma(element.value)
                const replacement = number.replace(/^0+/, '')
                const isZero = pNumber === '0' && replacement === ''
                element.value = isZero ? '0' : replacement
            }
        },
        validateNumber: function (singleNumber) {
            if (!/^\d+$/.test(singleNumber)) {
                return false
            }
            return true
        },
        setInputGa: function () {
            if (textBoxGaSetting.includes(this.name)) return
            else
                this.$setGaEvent(
                    `${this.name}${this.required ? 'Required' : ''}Filter`,
                    'click-Filter'
                )
        }
    },
    computed: {
        dateMax: function () {
            if (this.type === 'date') {
                if (this.max === 'today') return this.$formatDate(new Date())
                else if (this.max) return this.max
            }

            return '9999-12-31'
        },
        dateMin: function () {
            if (this.type === 'date') {
                if (this.min === 'today') return this.$formatDate(new Date())
                else if (this.min === 'default') return '2010-01-01'
                //default為磊山創始日期
                else if (this.min) return this.min
            }
            return '1900-01-01'
        },
        switchColumnDefaultStyle: function () {
            if (this.onlyMonth) {
                return 'YYYY/MM'
            } else if (this.onlyDate) {
                return 'MM/DD'
            } else {
                return 'YYYY/MM/DD'
            }
        },
        textboxStyleClass: function () {
            let className = {}
            if (this.isError) className['error'] = true
            if (this.disabled) className['disabled'] = true
            //隱藏客製化遮罩：年/月/日 input 顯示
            if (this.disabled && this.allowInputDate && this.type === 'date')
                className['hidemask'] = true
            if (this.showPlaceholder) className['placeholder'] = true
            return className
        },
        containerStyle: function () {
            let style = {}
            if (typeof this.width === 'string' && this.width.includes('%')) {
                style['width'] = this.width
            } else if (typeof this.width === 'number') {
                style['width'] = `${this.width}px`
            }
            return style
        },
        allowInputDate: function () {
            return !(
                this.$isMobile('agent') ||
                this.isFirefox() ||
                this.onlyMonth
            )
        },
        flatpickrOptions: function () {
            const zh = require('flatpickr/dist/l10n/zh-tw.js').default.zh_tw
            const monthSelectPlugin = require('flatpickr/dist/plugins/monthSelect/index.js')
            const plugins = []
            const _this = this
            //根據日期篩選條件決定顯示字樣
            let outputFont = ''
            if (this.onlyMonth) {
                outputFont = 'Y / m'
            } else if (this.onlyDate) {
                outputFont = 'm / d'
            } else {
                outputFont = 'Y / m / d'
            }
            if (this.onlyMonth) {
                plugins.push(
                    new monthSelectPlugin({
                        shorthand: true, //defaults to false
                        dateFormat: 'Y-m',
                        //defaults to "F Y"
                        altFormat: outputFont
                    })
                )
            }

            // 可選日期的設置清空日期按鈕
            plugins.push(
                new ShortcutButtonsPlugin({
                    button: {
                        label: '清除'
                    },
                    onClick: (index, fp) => {
                        fp.clear()
                        fp.close()
                    }
                })
            )

            // //清除年跟每週日期欄位的部分
            if (this.onlyDate) {
                this.$nextTick(() => {
                    let yearInputs = document.querySelectorAll(
                        '.numInputWrapper'
                    )
                    let monthSelect = document.querySelectorAll(
                        '.flatpickr-current-month'
                    )
                    let weekNavbars = document.querySelectorAll(
                        '.flatpickr-weekdays'
                    )
                    yearInputs.forEach((yearColumn) => {
                        yearColumn.style.display = 'none'
                        yearColumn.style.width = 0
                    })
                    monthSelect.forEach(
                        (monthEl) => (monthEl.style.marginLeft = '5%')
                    )
                    weekNavbars.forEach(
                        (weekColumn) => (weekColumn.style.display = 'none')
                    )
                })
            }

            return {
                clickOpens: false,
                disableMobile: true,
                dateFormat: 'Y-m-d',
                maxDate: this.dateMax,
                minDate: this.dateMin,
                altInput: true,
                // altFormat: this.onlyMonth ? 'Y / m' : 'Y / m / d',
                altFormat: outputFont,
                altInputClass: `textbox date ${
                    this.allowInputDate ? 'flatpickr-mask' : ''
                } ${Object.keys(this.textboxStyleClass).join(' ')}`,
                locale: zh,
                plugins: plugins,
                onOpen: function (selectedDates, dateStr, instance) {
                    //須加上readonly屬性，手機版模擬鍵盤才不會出現
                    instance.altInput.setAttribute('readonly', 'readonly')
                    _this.isFocus = true
                    //修正空值時，日曆選單停留在無法選取範圍內，造成年份選單無法調整之錯誤
                    if (selectedDates.length === 0) {
                        const maxDate = instance.config.maxDate
                        if (_this.onlyMonth) {
                            instance.setDate(maxDate)
                            instance.set(maxDate)
                            //修復value透過js更改時，僅選月份的日曆的max、min的限制不正確，需透過點擊上下月份才能渲染正確樣式，需要再優化
                            instance.prevMonthNav.click()
                            instance.nextMonthNav.click()
                            _this.$emit('update:modelValue', _this.dateMax)
                        } else {
                            instance.clear()
                            //區間日期maxDate動態調整導致沒有modelValue為空，選單畫面點開需停留在maxDate的月份
                            if (_this.max && _this.min) {
                                instance._initialDate = maxDate
                                instance.currentMonth = maxDate.getMonth()
                                instance.currentYear = maxDate.getFullYear()
                            }
                        }
                    }
                },
                onClose: function (selectedDates, dateStr, instance) {
                    //blur手機版避免出現鍵盤造成日期用輸入造成格式錯誤
                    //須移除readonly屬性，required才能生效
                    instance.altInput.blur()
                    instance.altInput.removeAttribute('readonly')
                    _this.isFocus = false
                }
            }
        },
        invalidMsg: function () {
            return this.inputErrorMsg || `請選擇${this.title}`
        }
    },
    watch: {
        modelValue: function () {
            this.showPlaceholder = !this.modelValue
            this.dateValue = this.modelValue
            if (this.onlyMonth) {
                this.$nextTick(() => {
                    //修復value透過js更改時，僅選月份的日曆的max、min的限制不正確，需透過點擊上下月份才能渲染正確樣式，需要再優化
                    const fp = this.$refs.flatPickr.fp
                    fp.prevMonthNav.click()
                    fp.nextMonthNav.click()
                })
            }
            if (this.modelValue) {
                this.$refs.inputContainer.classList.remove(
                    'input-error-ci-style'
                )
            }
        },
        disabled: function () {
            let dateNodes = this.$refs.inputContainer.querySelectorAll(
                'input.date'
            )
            dateNodes.forEach((dateInputNode) => {
                dateInputNode.classList.toggle('disabled')
            })
        },
        dateMax: function () {
            this.$nextTick(() => {
                const fp = this.$refs.flatPickr.fp
                if (this.onlyMonth) {
                    if (fp.selectedDates.length === 0) {
                        const dateArr = this.dateMax.split('-')
                        if (dateArr.length > 0) {
                            fp.currentYear = Number(dateArr[0])
                            fp.currentMonth = Number(dateArr[1])
                        }
                        this.$emit('update:modelValue', null)
                    } else {
                        fp.setDate(this.dateValue)
                    }
                    fp.redraw()
                } else {
                    fp.redraw()
                    fp.setDate(this.dateValue)
                }
            })
        }
    },
    data() {
        return {
            dateValue: this.modelValue,
            isHovering: {
                input: false,
                calendar: false
            },
            showPlaceholder: !this.modelValue,
            isFocus: false
        }
    },
    mounted() {
        if (this.type === 'date') {
            const fp = this.$refs.flatPickr.fp
            this.$addAllClass(fp.monthsDropdownContainer, 'select')
            fp.altInput.removeAttribute('readonly')
            fp.altInput.onclick = () => {
                fp.toggle()
            }
            fp.altInput.onfocus = () => {
                fp.altInput.blur()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.hidemask {
    opacity: 0;
}
.info-icon {
    margin-left: 5px;
    width: 14px;
    height: 14px;
}
.input-container {
    width: 210px;
    margin-bottom: 28px;
    overflow: hidden;

    &:last-child {
        margin-right: 0px;
    }
}

::-webkit-input-placeholder {
    color: $placeholder-black;
}

:-moz-placeholder {
    color: $placeholder-black;
    opacity: 1;
}

::-moz-placeholder {
    color: $placeholder-black;
    opacity: 1;
}

::-ms-placeholder {
    color: $placeholder-black;
    opacity: 1;
}

::placeholder {
    color: $placeholder-black;
}

input,
:deep(.textbox) {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: $primary-grey;
    border: 1px solid $primary-grey;
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
    padding: 10px;
    color: $seventh-black;
    line-height: 16px;

    /*
        prevent iOS scale
        enlarge by 16/14 = 114.285714286%
        scale down by 14/16 = 0.875%
        height 40/0.875 = 45.7142857143
    */
    font-size: 16px;
    height: 45.7142857143px;
    width: 114.285714286%;
    transform: scale(0.8753);
    transform-origin: left top;

    &[type='date'],
    &.date {
        cursor: pointer;
        &::-webkit-datetime-edit-year-field,
        &::-webkit-datetime-edit-month-field,
        &::-webkit-datetime-edit-day-field,
        &::-webkit-datetime-edit-text {
            color: $seventh-black;
        }

        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            display: none;
        }

        &::-webkit-calendar-picker-indicator {
            -webkit-appearance: none;
            display: none;
            // opacity: 0;
            // width: 10%;
            // cursor: pointer;
        }

        &.placeholder {
            &:before {
                color: $placeholder-black;
                content: attr(placeholder);
            }
            &::-webkit-datetime-edit-fields-wrapper {
                display: none;
            }
        }
    }

    &.error {
        border: 1px solid $primary-red !important;
    }

    &.flatpickr-mask {
        float: left;
        // opacity: 0;
        margin-top: -46px;
    }
    &:focus:not(.disabled),
    &.focus:not(.disabled) {
        border: 1px solid $third-blue;
    }
}

.form-label {
    color: $sixth-black;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    .required {
        color: $primary-red;
    }
}

.error-message {
    position: absolute;
    font-size: 12px;
    line-height: 12px;
    color: $fourth-red;
    margin-top: -1.71px; //配合input scale
}
::v-global(.flatpickr-calendar) {
    user-select: none;
}

::v-global(.flatpickr-monthDropdown-months.select) {
    &,
    &:hover {
        width: 105px;
        background-color: $primary-white;
        background-image: url('../assets/images/btn_dropdown_normal.webp') !important;
        background-position: 70% !important;
        background-repeat: no-repeat !important;
        background-size: 12px !important;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
}
::v-global(.shortcut-buttons-flatpickr-buttons) {
    justify-content: center;
    width: 100%;
    padding: 5px;
}
</style>
